{
  "name": "proximilar",
  "version": "18.2.0",
  "private": true,
  "scripts": {
    "ng": "ng",

    "start": "ng serve",

    "build": "ng build --source-map",
    "build:stats": "ng build --stats-json",
    "build:demo": "ng build --configuration demo --source-map",
    "build:prod": "ng build --configuration production --source-map",

    "watch": "ng build --watch --configuration development",

    "test": "ng test",

    "prettier:check": "prettier --list-different 'src/{app,environments}/**/*{.html,.ts,.js,.json}'",
    "prettier": "prettier --write 'src/{app,environments}/**/*{.html,.ts,.js,.json}'",
    "prettier:package": "node scripts/format-package-json.js",

    "lint:styles": "yarn stylelint 'src/**/*.scss'",
    "lint:styles:fix": "yarn stylelint 'src/**/*.scss' --fix",
    "lint:eslint": "ng lint",
    "lint:eslint:fix": "ng lint --fix",
    "lint:staged": "yarn lint:eslint && yarn prettier:check",
    "lint": "yarn lint:eslint:fix && yarn prettier && yarn lint:styles",

    "serve:ssr:client": "node dist/server/server.mjs",

    "prepare": "cd .. && husky install client/.husky"
  },
  "dependencies": {
    "@angular/animations": "^18.2.11",
    "@angular/cdk": "^18.2.12",
    "@angular/common": "^18.2.11",
    "@angular/compiler": "^18.2.11",
    "@angular/core": "^18.2.11",
    "@angular/forms": "^18.2.11",
    "@angular/material": "^18.2.12",
    "@angular/platform-browser": "^18.2.11",
    "@angular/platform-browser-dynamic": "^18.2.11",
    "@angular/platform-server": "^18.2.11",
    "@angular/router": "^18.2.11",
    "@angular/ssr": "^18.2.11",
    "@auth0/auth0-angular": "^2.2.3",
    "@cloudfactorydk/ng2-tooltip-directive": "^18.0.0",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/operators": "^18.1.1",
    "@ngrx/router-store": "18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "angular-resizable-element": "^7.0.2",
    "angular-svg-icon": "^18.0.2",
    "apexcharts": "^4.0.0",
    "d3": "^7.9.0",
    "express": "^4.21.1",
    "lodash-es": "^4.17.21",
    "ng-apexcharts": "^1.12.0",
    "ngx-resize": "^2.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.8.1"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular-eslint/builder": "^18.4.0",
    "@angular-eslint/eslint-plugin": "^18.4.0",
    "@angular-eslint/eslint-plugin-template": "^18.4.0",
    "@angular-eslint/schematics": "^18.4.0",
    "@angular-eslint/template-parser": "^18.4.0",
    "@angular/cli": "~18.2.11",
    "@angular/compiler-cli": "^18.2.11",
    "@ngrx/store-devtools": "^18.1.1",
    "@types/d3": "^7.4.0",
    "@types/express": "^5.0.0",
    "@types/jasmine": "~5.1.4",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^22.9.0",
    "@typescript-eslint/eslint-plugin": "^8.14.0",
    "@typescript-eslint/parser": "^8.14.0",
    "dotenv-cli": "^7.4.2",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-change-detection-strategy": "^0.1.5",
    "eslint-plugin-prettier": "^5.2.1",
    "eslint-plugin-rxjs": "^5.0.3",
    "eslint-plugin-simple-import-sort": "^12.1.1",
    "eslint-plugin-unused-imports": "^4.1.4",
    "husky": "^9.1.6",
    "jasmine-core": "~5.4.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.2.10",
    "mime": "^4.0.4",
    "prettier": "^3.3.3",
    "prettier-eslint": "^16.3.0",
    "stylelint": "^16.10.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "stylelint-declaration-strict-value": "^1.10.6",
    "typescript": "~5.5.4",
    "zone.js": "~0.14.10"
  },
  "lint-staged": {
    "src/**/*.scss": [
      "stylelint"
    ],
    "src/**/*.{html,ts}": [
      "prettier",
      "eslint"
    ]
  }
}
